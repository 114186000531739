import React, { useState } from "react"
import { Container } from "../Container"
import * as styles from "./calendar.module.scss"
import { useQuery } from "@apollo/client"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import { objFormatter } from "../../utils/objFormatter"
import { BounceLoader } from "react-spinners"
import { GET_EVENTS_QUERY } from "../../apollo/queries/getEventsQuery"
import { colors } from "./colors"
import moment from "moment"

export const Calendar = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const { loading, error, data } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      startDateAfter: startDate,
      startDateBefore: endDate,
    },
    skip: !startDate || !endDate,
  })

  const handleDateSet = ({ startStr, endStr }) => {
    setStartDate(startStr)
    setEndDate(endStr)
  }

  const getEventClassNames = event => {
    const { isPast, backgroundColor } = event

    if (isPast) {
      return ["past-event"]
    }

    if (backgroundColor) {
      return [colors[backgroundColor]]
    }

    return []
  }

  const apolloData = data?.allEvents?.edges.map(({ node }) =>
    objFormatter(node)
  )

  const calendarData = apolloData?.map(item => {
    const { title, startDate, endDate, category, meta, organiser } = item

    const formattedTime = moment.utc(startDate).format("h:mm A")

    return {
      title: `${category.name.toUpperCase()} | ${title}`,
      organiser: organiser,
      time: formattedTime,
      start: startDate,
      end: endDate,
      backgroundColor: category.color,
      url: `/event/${meta.uid}`,
    }
  })

  const changeCalendarInfo = eventInfo => {
    const { time, organiser } = eventInfo.event._def.extendedProps
    return {
      html: `<div class="calendarInfo">
                <div class="time">Time: ${time} (UK time)</div>
                <div class="title">${eventInfo.event._def.title}</div>
                <div class="organiser">Organiser: ${organiser}</div>
            </div>`,
    }
  }

  return (
    <Container>
      <div className={styles.wrapper}>
        {error ? (
          <div>Something was wrong...</div>
        ) : (
          <div
            className={[loading ? styles.calendarWrapper : "show"].join(" ")}
          >
            <div className={styles.loader}>
              <BounceLoader color="#473974" loading={loading} />
            </div>
            <FullCalendar
              timeZone="local"
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={calendarData}
              datesSet={handleDateSet}
              eventClassNames={getEventClassNames}
              weekends={false}
              eventContent={changeCalendarInfo}
            />
          </div>
        )}
      </div>
    </Container>
  )
}
